import { useEffect, useState } from 'react'
import {
	VerticalTimeline,
	VerticalTimelineElement,
} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css'
import SchoolIcon from '@mui/icons-material/School'
import WorkIcon from '@mui/icons-material/Work'
import StarIcon from '@mui/icons-material/Star'
import QuestionMarkIcon from '@mui/icons-material/QuestionMark'
import AnimatedLetters from '../AnimatedLetters'
import './style.scss'
import jobs from '../../assets/data/jobs.json'
import schools from '../../assets/data/schools.json'

let listCounter = 0

const getUniqueId = () => `timeline-${listCounter++}`

const Timeline = () => {
	const [letterClass, setLetterClass] = useState('text-animate')
	const workText = [...'My work experience']
	const educationText = [...' & education']

	useEffect(() => {
		setTimeout(() => setLetterClass('text-animate-hover'), 3000)
	}, [])

	const getTitle = (title) =>
		title ? (
			<h1 className="vertical-timeline-element-title">{title}</h1>
		) : undefined

	const getLocation = (location) =>
		location ? (
			<h3 className="vertical-timeline-element-subtitle">{location}</h3>
		) : undefined

	const getTags = (tags) =>
		tags?.map((tag) => (
			<div
				role="button"
				className={tag === 'Australia' ? 'tag australia' : 'tag'}
				key={getUniqueId()}
			>
				<span className="tag-content">{tag}</span>
			</div>
		))

	const getSubMissions = (subMissions) =>
		subMissions?.[0] ? (
			<ul>
				{subMissions.map((subMission) => (
					<li key={getUniqueId()}>{subMission}</li>
				))}
			</ul>
		) : undefined

	const getMissions = (missions) =>
		missions?.[0] ? (
			<ul>
				{missions.map((mission) => (
					<li key={getUniqueId()}>
						{mission.title}
						{getSubMissions(mission.subMissions)}
					</li>
				))}
			</ul>
		) : undefined

	const getCitation = (citation) =>
		citation ? (
			<div className="citation">
				{citation.text}
				<br />
				<b>{citation.author}</b>
			</div>
		) : undefined

	const getTimelineElementList = (type, icon, list) =>
		list.map((item) => (
			<VerticalTimelineElement
				className={`vertical-timeline-element--${type}`}
				date={item.date}
				icon={icon}
				key={getUniqueId()}
			>
				{getTags(item.tags)}
				<br />
				<br />
				{getTitle(item.title)}
				{getLocation(item.location)}
				<span className="vertical-timeline-element-description">
					{getMissions(item.missions)}
					{getCitation(item.citation)}
				</span>
			</VerticalTimelineElement>
		))

	return (
		<>
			<div className="container timeline-page" id="work">
				<div className="heading">
					<h1>
						<AnimatedLetters
							letterClass={letterClass}
							strArray={workText}
							idx={1}
						/>
						<span className="break" />
						<AnimatedLetters
							letterClass={letterClass}
							strArray={educationText}
							idx={workText.length}
						/>
					</h1>
				</div>
				<div>
					<VerticalTimeline animate={true}>
						<VerticalTimelineElement
							className="vertical-timeline-element--question-mark"
							icon={<QuestionMarkIcon />}
						/>

						{getTimelineElementList('work', <WorkIcon />, jobs)}
					</VerticalTimeline>
				</div>
				<div id="education">
					<VerticalTimeline animate={true}>
						{getTimelineElementList(
							'education',
							<SchoolIcon />,
							schools
						)}

						<VerticalTimelineElement
							className="vertical-timeline-element--star"
							icon={<StarIcon />}
						/>
					</VerticalTimeline>
				</div>
			</div>
		</>
	)
}

export default Timeline
