import { useEffect, useState } from 'react'
import AnimatedLetters from '../AnimatedLetters'
import Card from '../Card'
import './style.scss'
import projects from '../../assets/data/projects.json'

const Projects = () => {
	const [letterClass, setLetterClass] = useState('text-animate')
	let listCounter = 0

	const getUniqueId = () => `project-${listCounter++}`

	useEffect(() => {
		setTimeout(() => setLetterClass('text-animate-hover'), 3000)
	}, [])

	return (
		<>
			<div className="container projects-page" id="projects">
				<div className="heading">
					<h1>
						<AnimatedLetters
							letterClass={letterClass}
							strArray={[...'My projects']}
							idx={1}
						/>
					</h1>
				</div>
				<div className="cards-container">
					{projects.map((project) => (
						<Card
							title={project.title}
							image={project.image}
							button={project.button}
							description={project.description}
							key={getUniqueId()}
						/>
					))}
				</div>
			</div>
		</>
	)
}

export default Projects
