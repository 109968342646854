import React, { useRef, useState, useEffect, useCallback } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
	faHome,
	faEnvelope,
	faSuitcase,
	faBars,
	faTimes,
	faCode,
	faGraduationCap,
} from '@fortawesome/free-solid-svg-icons'
import './style.scss'

const Navbar = () => {
	const navRef = useRef()
	const [prevScrollPos, setPrevScrollPos] = useState(0)
	const [visible, setVisible] = useState(true)

	const showNavbar = () => navRef.current.classList.toggle('responsive-nav')

	const handleScroll = useCallback(() => {
		const currentScrollPos = window.pageYOffset
		if (currentScrollPos === prevScrollPos) return
		setPrevScrollPos(currentScrollPos)
		if (!visible) {
			if (prevScrollPos > currentScrollPos || currentScrollPos < 10)
				setVisible(true)
			return
		}
		if (prevScrollPos < currentScrollPos && currentScrollPos > 100)
			setVisible(false)
	}, [prevScrollPos, visible])

	useEffect(() => {
		window.addEventListener('scroll', handleScroll)
		return () => window.removeEventListener('scroll', handleScroll)
	}, [prevScrollPos, visible, handleScroll])

	const getHeaderVisibility = (isVisible) =>
		isVisible
			? {
					visibility: 'visible',
					opacity: '1',
					transition: 'visibility 0s linear 0s, opacity 300ms',
			  }
			: {
					visibility: 'hidden',
					opacity: '0',
					transition: 'visibility 0s linear 300ms, opacity 300ms',
			  }

	const getNavigationButtonVisibility = (isVisible) =>
		!isVisible ? { visibility: 'hidden' } : undefined

	const getLinkedIcon = (icon, href) => (
		<a href={href}>
			<FontAwesomeIcon icon={icon} onClick={showNavbar} />
		</a>
	)

	return (
		<header style={getHeaderVisibility(visible)}>
			<nav ref={navRef}>
				{getLinkedIcon(faHome, '/#')}
				{getLinkedIcon(faSuitcase, '/#work')}
				{getLinkedIcon(faGraduationCap, '/#education')}
				{getLinkedIcon(faCode, '/#projects')}
				{getLinkedIcon(faEnvelope, '/#contact')}
				<button className="nav-btn nav-close-btn" onClick={showNavbar}>
					<FontAwesomeIcon icon={faTimes} />
				</button>
			</nav>
			<button
				className="nav-btn"
				style={getNavigationButtonVisibility(visible)}
				onClick={showNavbar}
			>
				<FontAwesomeIcon icon={faBars} />
			</button>
		</header>
	)
}

export default Navbar
