import React from 'react'
import Loader from 'react-loaders'
import Navbar from '../Navbar'
import Home from '../Home'
import Timeline from '../Timeline'
import Projects from '../Projects'
import './style.scss'
import Contact from '../Contact'

function App() {
	return (
		<>
			<React.Fragment>
				<Navbar />
				<div className="page">
					<Home />
					<Timeline />
					<Projects />
					<Contact />
				</div>
				<Loader type="ball-grid-pulse" />
			</React.Fragment>
		</>
	)
}

export default App
