import './style.scss'

const Card = ({ title, image, button, description }) => {
	let listCounter = 0

	const getUniqueId = () => `card-${listCounter++}`

	const getImage = (cardImage) =>
		cardImage ? (
			<div className="card-image">
				<img src={cardImage.src} alt={cardImage.alt} />
			</div>
		) : undefined

	const getTitle = (cardTitle) =>
		cardTitle ? (
			<div className="card-title">
				<h1>{cardTitle}</h1>
			</div>
		) : undefined

	const getTextElement = (textElement) => textElement.text

	const getLinkElement = (linkElement) =>
		linkElement ? (
			<a href={linkElement.href} alt={linkElement.alt}>
				{linkElement.text}
			</a>
		) : undefined

	const getBreakElement = () => <br />

	const getListElement = (listElement) =>
		listElement?.list?.[0] ? (
			<ul>
				{listElement.list.map((element) => (
					<li key={getUniqueId()}>
						{getDescriptionElement(element)}
					</li>
				))}
			</ul>
		) : undefined

	const getDescriptionElement = (element) => {
		switch (element.type) {
			case 'text':
				return <>{getTextElement(element)}&nbsp;</>
			case 'link':
				return <>{getLinkElement(element)}&nbsp;</>
			case 'break':
				return getBreakElement()
			case 'list':
				return getListElement(element)
			default:
				return undefined
		}
	}

	const getDescription = (cardDescription) =>
		cardDescription?.[0] ? (
			<div className="card-description">
				<span className="card-description-content">
					{cardDescription.map((element) => (
						<span key={getUniqueId()}>
							{getDescriptionElement(element)}
						</span>
					))}
				</span>
			</div>
		) : undefined

	const getButton = (cardButton) =>
		cardButton ? (
			<div className="card-button">
				<button>{getLinkElement(cardButton)}</button>
			</div>
		) : undefined

	return (
		<>
			<div className="card-container">
				{getImage(image)}
				<div className="card-content">
					{getTitle(title)}
					{getDescription(description)}
				</div>
				{getButton(button)}
			</div>
		</>
	)
}

export default Card
