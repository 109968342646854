import { useEffect, useState } from 'react'
import {
	faNodeJs,
	faUnity,
	faSquareJs,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import AnimatedLetters from '../AnimatedLetters'
import CSharpLogo from '../../assets/svg/c-sharp-logo.svg'
import TSLogo from '../../assets/svg/typescript-logo.svg'
import './style.scss'

const Home = () => {
	const [letterClass, setLetterClass] = useState('text-animate')

	const hiArray = [...'Hi,']
	const nameArray = [..."I'm Richard"]
	const jobArray = [...'backend developer']
	const delay = 8

	useEffect(() => {
		setTimeout(() => setLetterClass('text-animate-hover'), 5000)
	}, [])

	return (
		<>
			<div className="container home-page">
				<div className="text-zone">
					<div className="text-box">
						<h1>
							<AnimatedLetters
								letterClass={letterClass}
								strArray={hiArray}
								idx={delay}
							/>
							<br />
							<AnimatedLetters
								letterClass={letterClass}
								strArray={nameArray}
								idx={delay + hiArray.length + delay}
							/>
							<br />
							<AnimatedLetters
								letterClass={letterClass}
								strArray={jobArray}
								idx={
									delay +
									hiArray.length +
									delay +
									nameArray.length
								}
							/>
						</h1>
						<h2>
							JavaScript / Node.js / Typescript / C# / Unity3D
						</h2>
						<a href="/#contact" className="flat-button">
							CONTACT ME
						</a>
					</div>
				</div>
				<div className="logos-container">
					<FontAwesomeIcon
						icon={faNodeJs}
						color="#44883e"
						className="svg-logo"
						style={{ animationDelay: '-3s' }}
					/>
					<img
						src={TSLogo}
						alt="Typescript Logo"
						className="svg-logo ts"
						style={{ animationDelay: '-6s' }}
					/>
					<FontAwesomeIcon
						icon={faSquareJs}
						color="#f0db4f"
						className="svg-logo"
						style={{ animationDelay: '-9s' }}
					/>
					<FontAwesomeIcon
						icon={faUnity}
						color="#ffff"
						className="svg-logo"
						style={{ animationDelay: '-12s' }}
					/>
					<img
						src={CSharpLogo}
						alt="C# Logo"
						className="svg-logo"
						style={{ animationDelay: '-15s' }}
					/>
				</div>
			</div>
		</>
	)
}

export default Home
