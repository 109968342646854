import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLinkedin, faGithub } from '@fortawesome/free-brands-svg-icons'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'
import AnimatedLetters from '../AnimatedLetters'
import './style.scss'

const Contact = () => {
	const [letterClass, setLetterClass] = useState('text-animate')
	const [emailText, setEmailText] = useState('(click here to reveal)')
	const encodedEmail = 'cmljaGFyZC5qLmFsbGVtYW5AZ21haWwuY29t'

	useEffect(() => {
		setTimeout(() => setLetterClass('text-animate-hover'), 3000)
	}, [])

	const getEmailFromEncoded = (encoded) => atob(decodeURIComponent(encoded))

	const getLinkedIcon = (icon, href, alt) => (
		<a href={href} alt={alt} target="_blank" rel="noreferrer">
			<FontAwesomeIcon icon={icon} />
		</a>
	)

	return (
		<>
			<div className="container contact-page" id="contact">
				<div className="heading">
					<h1>
						<AnimatedLetters
							letterClass={letterClass}
							strArray={[...'Contact me']}
							idx={1}
						/>
					</h1>
				</div>
				<div className="content-container">
					<div className="text-zone">
						<span className="text-content">
							<p>
								Feel free to contact me at my email address{' '}
								<span
									onClick={(e) => {
										setEmailText(
											getEmailFromEncoded(encodedEmail)
										)
										e.target.style.cursor = 'default'
									}}
									className="email"
								>
									{emailText}
								</span>
							</p>
						</span>
						<div className="icons-zone">
							{getLinkedIcon(
								faGithub,
								'https://github.com/Maugun',
								'github'
							)}
							{getLinkedIcon(
								faLinkedin,
								'https://www.linkedin.com/in/richard-alleman/',
								'linkedin'
							)}
							{getLinkedIcon(
								faFilePdf,
								'/files/Resume_Richard_ALLEMAN.pdf',
								'curriculum vitae'
							)}
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Contact
